main {
  overflow: hidden;
}

body {
  font-family: "Poppins", sans-serif !important;
}
h1 {
  font-family: "Poppins", sans-serif !important;
}
h2 {
  font-family: "Poppins", sans-serif !important;
}
h3 {
  font-family: "Poppins", sans-serif !important;
}
h4 {
  font-family: "Poppins", sans-serif !important;
}
h5 {
  font-family: "Poppins", sans-serif !important;
}
h6 {
  font-family: "Poppins", sans-serif !important;
}
a {
  font-family: "Poppins", sans-serif !important;
}
p {
  font-family: "Poppins", sans-serif !important;
}
span {
  font-family: "Poppins", sans-serif !important;
}
.btn {
  font-family: "Poppins", sans-serif !important;
}
.t-30 {
  font-size: 30px !important;
}
.mr-5 {
  margin-right: 5px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
}

.selectbox {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 26px;
  padding: 8px;
  font-size: 12px;
  line-height: 21px;
  cursor: pointer;
  margin-bottom: 10px;
  height: 40px;
  min-width: 140px !important;
  margin-left: 5px;
}
input:focus,
input:hover,
textarea:focus,
select:focus {
  outline: none !important;
}

.bkg-white {
  background: #ffffff;
}

.text-black {
  color: #242424;
}

.online {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1px 29px;
  width: 104px;
  height: 23px;
  background: #e8fff3;
  border-radius: 19px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #339966;
}
.offline {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 29px;
  width: 104px;
  height: 23px;
  background: #ffe4e4;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #db5f5f;
  border-radius: 19px;
}
.blocked {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: center;
  padding: 1px 29px;
  width: 104px;
  height: 23px;
  background: #ccc;
  border-radius: 19px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #242424;
}

/* Add Animation */

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.float {
  float: right;
  height: 35px;
  min-width: 70px;
}
.downloadBtns {
  min-width: 150px;
}
.downloadLinks {
  margin-left: 3rem;
}

.register {
  left: 20%;
  top: 50%;
}
.dataTable {
  padding: 30px;
}
.wicryptLogo {
  background-size: contain !important;
}
.alternative {
  cursor: pointer;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.authbtns {
  justify-content: flex-end;
}
.marketer {
  height: 35px;
  min-width: 70px;
}

.enterCode {
  margin-top: 20px;
}
.rounded-circle {
  background-color: #acacb1;
}

.w-700 {
  font-weight: 700;
}
/* static buttons */
.static-success-button {
  min-width: 104px;
  border-radius: 19px;
  padding: 1px, 29px, 1px, 29px;
  background: #e8fff3;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #339966;
  margin-right: 10px;
}
.static-pending-button {
  min-width: 104px;
  border-radius: 19px;
  padding: 1px, 29px, 1px, 29px;
  background: #fbfbe3;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #e5b910;
  margin-right: 10px;
}
.static-error-button {
  min-width: 104px;
  border-radius: 19px;
  padding: 1px, 29px, 1px, 29px;
  background: #ffe8e8;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #db5f5f;
  margin-right: 10px;
}

/* flex */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.zebra-styled {
  background: #c4c4c41a;
}

.items-center {
  align-items: center;
}
.items-left {
  align-items: flex-start;
}
.items-right {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}

@media screen and (max-min-width: 560px) {
  .responsiveTable {
    overflow: scroll !important;
    min-width: 100vw;
  }
  .hidden-lg {
    display: block !important;
  }
  .theWelctext {
    display: none;
  }
}

li {
  list-style: none;
}

.wicryptColor {
  background-color: #344684 !important;
  color: white !important;
}

.activate {
  background-color: green !important;
  color: white !important;
  border: none !important;
}
.deactivate {
  background-color: red !important;
  color: white !important;
  border: none !important;
}
.archive {
  background-color: #e5b909 !important;
  color: white !important;
  border: none !important;
}
.custom-modal-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  min-width: 100%;
  background-color: #0000009c;
  display: flex;
  z-index: 100000;
  justify-content: center;
  align-items: center;
}

.custom-modal-card {
  /* height: 100%; */
  border-radius: 3px;
  max-height: 600px;
  overflow: auto;
}

.actionButton {
  min-width: 100px;
}

.myforgot {
  text-align: right;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 400;
}
.forgot {
  font-size: 12px;
  line-height: 23px;
  color: #e5b909;
  font-style: italic;
}
.forgot:hover {
  color: #e5b909;
  opacity: 0.9;
  border-bottom: 1px solid;
}
.reset {
  color: #a2a2a2;
  font-style: normal;
}

.bb-2 {
  border-bottom: 2px solid #6e6e6e;
}
.choosePass {
  font-size: 12px;
  padding: 15px;
  margin-bottom: 0px;
}

.TransactionCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}

.inputfile {
  min-width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.uploads {
  display: inline-flex;
  text-align: center;
}

.inputfile + label {
  cursor: pointer;
  display: inline-block;
  padding: 15px;
  margin-top: 15px;
  border-radius: 8px;
  background: rgba(169, 186, 243, 0.15);
  border: 1px solid #a9baf3;
  font-size: 0.5rem;
  line-height: 18px;
  color: #344684;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #ffffff;
  color: #344684;
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.text-blue {
  color: #344684 !important;
}
.transactionTable tr td {
  color: #3f5292;
  min-width: 25%;
}
.transactionTable tr td b {
  font-weight: 600;
  min-width: 20%;
}
.details {
  border-radius: 8px;
  padding: 7px;
  color: #fff;
  background-color: #3f5292;
  font-size: 0.6rem;
  cursor: pointer;
  min-width: 100px;
  border: 1px solid rgba(207, 168, 9, 0.35);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.disabledButton {
  opacity: 0.5; 
  cursor: not-allowed; 
}
.mb-0 {
  margin-bottom: 0px;
}
.copyIcon {
  top: 1.4rem !important;
  font-size: 20px !important;
  color: #3f5292;
  cursor: pointer;
}
.copied {
  background-color: #3f5292;
  color: #fff;
  padding: 2px;
  border-radius: 8px;
  font-size: 12px;
  min-width: 50px;
  text-align: center;
}
.backBtn {
  box-shadow: 1px 1px 1px 2px #b7b7b7;
  padding: 10px;
  border-radius: 8px;
}
.bankDetails {
  font-weight: bold !important;
  color: #3f5292 !important;
  font-size: 16px !important;
}
.background {
  /* background-image: url('/Assets/bkg.png'); */
  background: transparent url("/Assets/bkg.svg") 0% 0% no-repeat padding-box;
  background-color: #000a5a;
  height: 100vh;
  background-size: cover;
}
.sessions {
  margin-bottom: 0px !important;
  margin-top: 15px;
}
.customBtn {
  border-radius: 15px !important;
  padding: 7px;
  border: 0px;
  font-size: 12px;
  box-shadow: 1px 1px 1px 2px #b7b7b7;
  cursor: pointer;
  min-width: 110px;
}
.pieViews {
  border-radius: 15px;
  box-shadow: 1px 1px 1px 2px #b7b7b7;
  padding: 7px;
  color: #3f5292;
}

.details:hover {
  box-shadow: 1px 1px 1px 1px #b7b7b7;
  transition: 0.5s;
}

.preview {
  box-shadow: 2px 2px 2px 2px #b7b7b7;
  border-radius: 8px;
}
.info {
  display: none;
}

.info-allocation {
  position: absolute;
  z-index: 1000;
  box-shadow: 2px 2px 2px 2px #b7b7b7;
  background-color: #fefefe;
  border-radius: 8px;
  padding: 10px;
  display: block;
}
button:focus {
  outline: none !important;
}

@media only screen and (max-min-width: 600px) {
  .scrollCard {
    overflow-x: scroll;
  }
  .scrollTable {
    min-width: 650px;
  }
  .theWelctext {
    display: none;
  }
  .sideWave {
    height: 100px;
  }
  .leftsideWave {
    height: 80px;
  }
}
button:disabled {
  cursor: not-allowed;
}
.Pending {
  background: #fcf8e6;
  border: 1px solid #cfa809;
  min-width: 100px;
  color: #cfa809;
  box-shadow: none !important;
  text-transform: capitalize;
  border-radius: 36px !important;
}
.Approved {
  background: #f4f4f4;
  border: 1px solid #b5b5b5 !important;
  border-radius: 36px !important;
  color: green;
  box-shadow: none !important;
  text-transform: capitalize;
  min-width: 100px;
}
.CONFIRMED {
  color: green !important;
  text-transform: capitalize;
  min-width: 100px;
}
.SUCCESS {
  color: green !important;
  text-transform: uppercase;
  min-width: 100px;
}
.COMPLETED {
  color: green !important;
  text-transform: uppercase;
  min-width: 100px;
}
.FAILED {
  color: red !important;
  text-transform: uppercase;
  min-width: 100px;
}
.PENDING {
  color: #cfa809 !important;
  text-transform: capitalize;
  min-width: 100px;
}
.ACKNOWLEDGED {
  color: #cfa809 !important;
  text-transform: uppercase;
  min-width: 100px;
}
.CANCELLED {
  color: red !important;
  text-transform: uppercase;
  min-width: 100px;
}
.SETSTATUS {
  color: #cfa809;
  text-transform: capitalize;
  min-width: 100px;
  cursor: pointer;
  border: 2px solid #cfa809;
  width: 30%;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}
.Approved:hover {
  color: #6b6b6b;
}
.Failed {
  background-color: red;
  color: white;
  padding: 7px;
  border: 0px;
  font-size: 12px;
  cursor: pointer;
  min-width: 100px;
}
.Rejected {
  background-color: red;
  color: white;
  padding: 7px;
  border: 0px;
  font-size: 12px;
  cursor: pointer;
  min-width: 100px;
}
.Cancelled {
  background: rgba(207, 9, 9, 0.08);
  border: 1px solid rgba(229, 9, 9, 0.27) !important;
  border-radius: 36px !important;
  color: rgba(255, 30, 30, 0.34);
  box-shadow: none;
  text-transform: capitalize;
  min-width: 100px;
  /* cursor: none !important; */
}
.Cancelled:hover {
  color: rgba(255, 30, 30, 0.34);
}
.verify {
  background: transparent;
  border: 1px solid #25346a !important;
  border-radius: 36px !important;
  color: #25346a;
  box-shadow: none;
  text-transform: capitalize;
  min-width: 100px;
}
.accept {
  background: #25346a;
  border: 1px solid #25346a !important;
  border-radius: 36px !important;
  color: #ffffff;
  box-shadow: none;
  min-width: 100px;
}
.accept:hover {
  color: #25346a;
  background-color: transparent;
}
.verify:hover {
  color: #ffffff;
  background-color: #25346a;
}
.reject {
  background: #fff8de;
  border: 1px solid rgba(229, 9, 9, 0.27) !important;
  border-radius: 36px !important;
  color: #cfa809;
  box-shadow: none;
  text-transform: capitalize;
  min-width: 100px;
}
.reject:hover {
  color: #cfa809;
}
.export-button {
  border-radius: 36px;
  color: #ffffff;
  font-size: 0.7rem;
  cursor: pointer;
  min-width: 10%;
  background: #e5b909;
  border: 1px solid rgba(207, 168, 9, 0.35);
  text-align: center;
  height: 40px;
  min-width: 183px;
}
.inactive {
  background-color: grey;
  opacity: 0.7;
}
.PendingList {
  background-color: orange;
  color: white;
  padding: 7px;
  border: 0px;
  font-size: 12px;
  cursor: pointer;
  min-width: 170px;
}
.Neutral {
  background-color: white;
  color: #2d4773;
  padding: 7px;
  border: 0px;
  font-size: 12px;
  cursor: pointer;
  min-width: 170px;
}
.pointer {
  cursor: pointer;
}
/* Style the Image Used to Trigger the Modal */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  min-width: 100%; /* Full min-width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  min-width: 80%;
  max-min-width: 700px;
}
.custom-modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  min-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Caption of Modal Image (Image Text) - Same min-width as the Image */
#caption {
  margin: auto;
  display: block;
  min-width: 80%;
  max-min-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.businessBalanceMsg {
  font-size: 14px;
  color: red;
  padding: 20px;
}
.otpMsg {
  font-size: 14px;
  color: red;
  font-weight: bold;
}
.swalMsg {
  font-size: 20px;
  font-weight: 100;
}

/* 100% Image min-width on Smaller Screens */
@media only screen and (max-min-width: 800px) {
  .modal-content {
    min-width: 100%;
  }
  .theWelctext {
    display: none;
  }
}

.loginCred {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 2px solid #e1e5eb !important;
  border-radius: 0% !important;
  transition: border 3s !important;
}
.fa-trash {
  color: red;
}
.loginCred:focus {
  box-shadow: none !important;
}
.dateRange {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 26px;
  padding: 8px;
  font-size: 12px;
  line-height: 21px;
  cursor: pointer;
  margin-bottom: 10px;
}
.p-8 {
  padding: 8px;
}

.cardHeading {
  font-size: 30px;
  color: #000000;
  line-height: 72px;
  font-weight: 600 !important;
}
.vouchercardHeading {
  font-size: 20px;
  font-weight: bold;
}
.loginBtn {
  display: flex;
  justify-content: center;
}

.clickLogin {
  /* font-size: 20px !important; */
  border-radius: 45px !important;
  min-width: 70%;
}
.t-blacl {
  color: #333333;
  font-size: 12px;
}
label {
  margin-bottom: 0px !important;
  font-size: 11px;
  font-weight: bold !important;
}

.privacy {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 0px;
}
.wicryptmsg {
  color: #3e3e3e;
  font-weight: bold;
}
.text-red {
  color: rgb(243, 75, 75) !important;
}
.text-green {
  color: #11a706;
}
.text-orange {
  color: #cfa809;
}
.errorMsgs {
  color: rgb(243, 75, 75);
  font-size: 10px;
  background-color: #feeeee;
  padding: 5px;
}
.d-flex {
  display: flex;
  justify-content: space-between;
}

.details-colum{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 55px;
  gap: 55px;
  justify-content: flex-start;
}
.d-spacearound {
  display: flex;
  justify-content: space-around;
}
.b-0 {
  border: none !important;
}
.volucherNote {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pointer {
  cursor: pointer;
}
.voucherText {
  background-color: #e5b909;
  color: #ffffff;
  padding: 15px;
  border-radius: 8px;
}
.voucherClose {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: px;
  color: black;
  font-size: 12px;
  cursor: pointer;
}
.sideWave {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 100px;
}
.leftsideWave {
  position: absolute;
  left: 0px;
  bottom: 0px;
  /* height: 80px; */
}
.logo {
  position: absolute;
  top: 5%;
  left: 5%;
  height: 20px;
}
.info {
  height: 10px !important;
}

input:focus {
  border-bottom: 2px solid #344684 !important;
  /* opacity: .4; */
  box-shadow: none;
}
.register {
  color: #2d4773;
  font-size: 12px;
}
.googleBtn {
  background: #ffffff;
  color: #000000;
  min-height: 50px;
  border: 2px solid #344684 !important;
  box-shadow: none;
}
.loginbutton {
  background: #344684;
  color: #ffffff;
  height: 50px;
  border: 2px solid #344684 !important;
  box-shadow: none;
}
.Funding {
  color: #81e195;
}
.weekly-Reward {
  color: #81e195;
}
.Transfer {
  color: rgba(255, 102, 0, 1);
}
.Withdrawal {
  color: rgba(255, 102, 0, 1);
}
.Withdraw {
  color: rgba(255, 102, 0, 1);
}
.Claim {
  color: rgba(255, 102, 0, 1);
}
.eye {
  height: 10px;
  position: absolute;
  right: 0px;
  cursor: pointer;
  z-index: 1000;
}
.h-200 {
  min-height: 200px;
}
.welcome {
  font-size: 35px;
  color: #ffffff;
  font-weight: bold;
}

.download {
  color: #ffffff;
}

.tanda {
  margin-left: 15px;
}
.policies {
  text-decoration: none !important;
  color: #c8d5ff;
  font-size: 14px;
}
.theWelctext {
  padding: 100px !important;
}
.googlePlay {
  background-color: #fff;
  border-radius: 34px !important;
  height: 40px;
  box-shadow: none;
  min-width: 27%;
}
.googlePlay > p {
  margin-bottom: 0px;
}
.googlePlay > div > img {
  height: 20px;
}
.googleClip {
  display: flex;
  margin-bottom: 0px !important;
}
.getItOn {
  margin-left: 14px;
  margin-bottom: 0px !important;
  font-size: 8px;
}
.bold {
  font-weight: 600 !important;
}

.googlePlayText {
  color: #000000;
  font-weight: bold;
  line-height: 0px;
  margin-left: 18px;
}
.lgLogo {
  margin-left: 7rem;
}
.btn-outline-primary {
  border-color: #2d4773 !important;
  color: #2d4773 !important;
}
.btn-outline-primary:hover {
  border-color: #2d4773 !important;
  color: #fff !important;
  background-color: #2d4773 !important;
}
.react-datetime-picker__wrapper {
  border: none !important;
}

.moneyMoves {
  display: flex;
  justify-content: space-between;
}
.previewImg {
  height: 100px;
  min-width: 100px;
  margin: 0 auto;
  overflow: hidden;
}

.businessImg {
  min-width: 100%;
}
.overviewVal {
  margin-top: 1rem;
  font-size: 20px;
  margin-bottom: 0px;
  color: #25346a;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 173.5%;
}
.t-right {
  text-align: right;
}
.t-8 {
  font-size: 8px;
}
.t-12 {
  font-size: 12px !important;
}
.fit-content {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}
.t-12 {
  font-size: 12px;
}
.t-13 {
  font-size: 13px;
}
.t-14 {
  font-size: 10px;
}
.t-16 {
  font-size: 14px;
}
.t-17 {
  font-size: 17px !important;
}
.t-20 {
  font-size: 20px !important;
}
.t-25 {
  font-size: 25px;
}
.t-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-bold {
  font-weight: bold !important;
}
.justify {
  justify-content: space-between;
  margin: 30px;
}
.jc {
  justify-content: space-between;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}
.t3 {
  font-size: 2.5rem !important;
}
.t2 {
  font-size: 1.5rem !important;
}
.md-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  }
@media (min-width: 992px) {
  .lgLogo {
    margin-left: 10rem !important;
  }
  .md-px-2 {
  padding-left: 0 !important;
  padding-right: 0 !important;
  }
}
.infoMsgs {
  color: white;
  background-color: #549b54;
  padding: 5px;
  border-radius: 3px;
}
.acctNameLoader {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 0.5rem;
  font-size: 16px;
}
.card {
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075) !important;
}
.shadow-table {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12) !important;
  overflow-x: auto;
}
.shadow-table:hover {
  box-shadow: 0 0.05rem 0.01rem rgba(75, 75, 90, 0.075) !important;
  transition: 1s;
}

.m-10 {
  margin: 10px;
}

.color-black {
  color: black !important;
}
.color-blue{
  color: #344684 !important
}
.trackers {
  font-weight: 600;
  padding-top: 12px;
}
.p-r {
  position: relative;
}
.p-20 {
  padding: 20px;
}
.pl-0 {
  padding-left: 0px;
}
.pl-15 {
  padding-left: 15px;
}
.sideCard {
  position: absolute;
  right: 0;
  box-shadow: 1px 1px 1px 2px #b7b7b7;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  z-index: 1000;
  top: 0;
}
.main-navbar .navbar .navbar-nav .dropdown-menu {
  min-width: 250px !important;
}
.mobileDrop {
  background-color: #fefefe;
  min-width: 250px;
  padding: 10px;
  border-radius: 8px;
}

.closeNav {
  font-size: 14px;
  font-weight: 700;
  float: right;
  padding: 20px;
}

.w-600 {
  font-weight: 600;
}

.mainnav {
  min-width: 100%;
  height: 6rem;
}

.fl-right {
  float: right;
}

.mobileNavItems {
  position: absolute;
  background-color: #fefefe;
  top: 100%;
  min-width: 100%;
  /* color: black; */
}

.createbtn {
  padding: 10px;
  background-color: #2d4773;
  color: #fefefe;
}
.sessionborder {
  border: 1px solid #2e2e2e;
  border-radius: 8px;
}

.w-50 {
  min-width: 50%;
}

.greybtn {
  font-size: 15px;
  min-width: 80%;
  border-radius: 50px;
  cursor: pointer;
  border: 1px solid #6e6e6e;
  color: #6e6e6e;
  background-color: #ffffff;
}

.formlook {
  min-width: 90%;
  margin: 0 auto;
}

.br-1 {
  border-radius: 8px;
  border: 1px solid #6e6e6e;
}

.pd-2 {
  padding: 2rem;
}

.w-100 {
  min-width: 100%;
}

.cycle-tab-container {
  margin: 30px auto;
  /* min-width: 800px; */
  padding: 20px;
  /* box-shadow: 0 0 10px 2px #ddd; */
}

.cycle-tab-container a {
  color: #173649;
  font-size: 16px;
  /* font-family: roboto; */
  text-align: center;
}

.tab-pane {
  /* text-align: center; */
  min-height: 300px;
  margin: 30px auto;
  /* min-width: 600px; */
  max-min-width: 100%;
}

.cycle-tab-item {
  min-width: 180px;
}

.cycle-tab-item:after {
  display: block;
  content: "";
  border-bottom: solid 3px orange;
  transform: scaleX(0);
  transition: transform 0ms ease-out;
}

.cycle-tab-item.active:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
  transition: transform 1000ms ease-in;
}

.nav-link:focus,
.nav-link:hover,
.cycle-tab-item.active a {
  border-color: transparent !important;
  color: orange;
}

.activetab {
  color: orange;
  font-weight: 700;
}

.grey-text {
  color: grey;
}

.personalCard {
  background: linear-gradient(150deg, #e5b909, #2d4773, #e5b909 100%);
  color: white;
  min-height: 150px !important;
}

.businessCard {
  background: linear-gradient(150deg, #2d4773, #2d4773 100%);
  color: white;
}

.text-grey {
  color: #5a6169;
}
.littleamt {
  color: #868d97;
}
/* .max-100{
    min-width: 100%
} */
.max-18 {
  /* max-min-width: 25%; */
  min-width: 0;
}
.table-label {
  font-size: 12px;
  text-transform: uppercase;
}
.pagination {
  display: inline-block;
  justify-content: center;
}

.pagination p {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination p.active {
  /* background-color: #E5B909; */
  color: #e5b909;
  /* border-radius: 5px; */
}

.pagination p:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.paginationBttns {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  list-style-type: none;
  color: black;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  cursor: pointer;
  margin-top: 20px;
}

.paginationActive {
  color: #e5b909;
}

.pagination p:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

@keyframes animate-loader {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 270px;
  }
}

.skeleton {
  background-color: #fefefe;
  padding: 2px 16px;
}
.skeleton-image {
  height: 150px;
  min-width: 100%;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  animation: animate-loader 1.6s infinite linear;
  background-size: 300px;
}
.skeleton-name {
  min-width: 150px;
  height: 20px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  animation: animate-loader 1.6s infinite linear;
  background-size: 300px;
}
.skeleton-location {
  min-width: 150px;
  height: 10px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  animation: animate-loader 1.6s infinite linear;
  background-size: 300px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.avatar__preview {
  min-width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 5px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar__upload {
  min-width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
}

.card-img-top {
  min-width: 100%;
  height: 30vw;
  object-fit: cover;
}

.uglyduckling {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.uglylabel {
  position: absolute;
  top: 60%;
  right: 30%;
  padding: 10px;
  box-shadow: 0px 3px 3px 0px #00000003;
  border-radius: 12px;
  background-color: #ffffff;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.badgerole {
  font-size: 12px;
  text-align: center;
  border-radius: 36px;
  color: #ffffff;
  background: #eed77d;
}
#toast {
  visibility: hidden;
  max-min-width: 50px;
  height: 50px;
  /*margin-left: -125px;*/
  margin: auto;
  background-color: #e5b909;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 1%;
  font-size: 17px;
  white-space: nowrap;
}
#toast #img {
  min-width: 50px;
  height: 50px;
  float: left;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  background-color: #fff;
  color: #e5b909;
}
#toast #desc {
  color: #fff;

  padding: 16px;

  overflow: hidden;
  white-space: nowrap;
}

#toast.show {
  visibility: visible;
  animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 4s,
    fadeout 0.5s 4.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes expand {
  from {
    min-width: 50px;
  }
  to {
    min-width: 600px;
  }
}

@keyframes stay {
  from {
    min-width: 600px;
  }
  to {
    min-width: 600px;
  }
}

@keyframes shrink {
  from {
    min-width: 600px;
  }
  to {
    min-width: 50px;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 60px;
    opacity: 0;
  }
}

.main-navbar .navbar {
  height: 7.75rem !important;
}
.main-sidebar {
  box-shadow: none !important;
}
.main-sidebar .navbar-brand {
  height: 7.75rem !important;
}
.input-group-btn {
  background: #344684;
  color: #ffffff;
  border: 2px solid #344684 !important;
  box-shadow: none;
}
.main-sidebar .nav-wrapper {
  height: calc(100vh - 5.75rem - 1px) !important;
}

.icon-holder {
  background: rgba(229, 185, 9, 0.1);
  padding: 20px;
  margin-right: 20px;
  border-radius: 100%;
}
.label-title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 19px;
  color: #747474;
}
.align-center {
  align-items: center;
}
.star {
  color: #e5b909;
  font-size: 10px;
}
.questionarecard {
  position: absolute;
  right: 7%;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(24 29 39 / 10%), 0 15px 30px rgb(24 29 39 / 10%);
  top: 22%;
}

.get-started {
  border-radius: 45px !important;
  min-width: 50%;
}

.signupwithgoogle {
  height: 20px;
}

.transcBkg {
  min-width: 32px;
  height: 32px;
  background: #afafaf;
  opacity: 0.2;
  border-radius: 50%;
}

.disconnected {
  background: rgba(229, 185, 9, 0.1);
  border-radius: 19px;
  color: #cfa809;
  text-align: center;
  padding: 5px;
  min-width: 100px;
}
.connected {
  text-align: center;
  color: #339966;
  background: #e8fff3;
  border-radius: 19px;
  padding: 5px;
  min-width: 100px;
}
.verified {
  color: #339966;
}
.unverified {
  color: #ff1e1e;
}

@media only screen and (min-width: 768px) {
  .modaltext {
    padding: 12rem !important;
    padding-top: 0px !important;
    padding-bottom: 3rem !important;
  }
  .custom-modal-content {
    min-width: 60%;
  }
  .get-started {
    min-width: 30%;
  }
  .t-14 {
    font-size: 14px;
  }
  .logo {
    height: 30px;
  }
  .cardHeading {
    font-size: 45px;
  }
  .t-16 {
    font-size: 16px;
  }
}

.navbar {
  box-shadow: none !important;
}
.main-sidebar .nav .nav-item .nav-link {
  border-bottom: none !important;
}

.main-sidebar .nav .nav-item .nav-link {
  /* font-size: 18px !important; */
  font-weight: 600 !important;
  line-height: 27px !important;
  color: #6b6b6b !important;
}

.nav-data {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.searchbar {
  background: #fdfdfd !important;
  border: 1px solid #dddddd !important;
  box-sizing: border-box !important;
  border-radius: 50px !important;
  height: 58px !important;
}
.searchbar:focus {
  border-bottom: none !important;
}

.total {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #6b6b6b;
  margin-bottom: 5px;
}
.balance {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: #344684;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.carlendarIcon {
  position: absolute !important;
  top: 23% !important;
  right: 5% !important;
  cursor: pointer;
}
.test {
  min-width: 100%;
  /* col-lg-9 col-md-9 col-sm-12 p-0 offset-lg-3 offset-md-3 */
  /* margin-left: 20% */
}

.singledaterange {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #d2cbcb !important;
  margin-bottom: 8px;
  height: 20px !important;
  padding-left: 0 !important;
  font-size: 0.7rem;
  min-width: 350px !important;
  color: #6b6b6b;
  font-size: 0.7rem !important;
  font-weight: bold !important;
}
.billingbox {
  border: 1px solid #6b6b6b;
  box-sizing: border-box;
  border-radius: 37px;
  display: flex;
  justify-content: space-between;
}
.billingtype {
  margin-bottom: 0;
  padding: 10px;
}
.billingicon {
  margin-bottom: 0px;
  padding-right: 10px;
  padding-top: 6px;
}

.wdth-100 {
  min-width: 100px;
}

.search {
  border: 1px solid #e8e8e8 !important;
  box-sizing: border-box !important;
  border-radius: 26px !important;
  margin-left: 10px !important;
}
.floatverification {
  float: right;
  margin-right: 25px;
}
.datepickerStyle {
  /* position: absolute; */
  /* right: 2%; */
}

.address:hover {
  color: #e5b909;
}

.searchposition {
  position: relative;
  left: -320%;
}

.selfie-img{
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 0;
  cursor: pointer;
}

.bkg-green{
  background-color: green !important;
}

.bkg-red{
  background-color: red !important;
}

.wallet-card{
  height: 160px;
  margin-bottom: 20px;
}

.text-green{
  color: green !important;
}

/* .text-red{
  color: red !important;
} */

.search-group {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.search-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: #e5b909;
  display: grid;
  place-items: center;
  border: 1px solid #6B6B6B;
  border-left: none;
  outline: none;
  border-radius: 0 26px 26px 0;
}

.search-btn svg {
  width: 24px;
  height: 24px;
}

.search-btn svg path {
 fill: #fff;
}

.topic-wrapper{
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.inner-topic{
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media (min-width: 767px){
  .topic-wrapper{
    width: 100%;
    display: flex;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .inner-topic{
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.debit {
  color: rgb(243, 75, 75) !important;
}
.credit {
  color: #11a706 !important;
}

.unstake, .unstaking {
  color: rgb(243, 75, 75) !important;
}
.stake, .staking {
  color: #11a706 !important;
}
.claimreward, .forceunlock, .stakingrewardclaim {
  color: #e5b909 !important;
}

@media screen and (max-width: 600px) {
  .table-on-mobile table {
    border: 0;
  }
  
  .table-on-mobile table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .table-on-mobile table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  .table-on-mobile table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  .table-on-mobile table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .table-on-mobile table td:last-child {
    border-bottom: 0;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.mb-8 {
  margin-bottom: 0.5rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.input-date {
  width: 100% !important;
}

.user-info {
  display: block;
}

.user-info p {
  color: #cfa809;
  transition: color 0.5s ease-in-out;
}

.user-info:hover p {
  color: #a78709;
}

.apr-update-btn {
  z-index: 1;
  right: 2rem;
  top: 2rem;
}

.apr-btn {
  background: #fff;
  border: 1px solid #b5b5b5 !important;
  border-radius: 36px !important;
  color: #cfa809;
  box-shadow: none !important;
  text-transform: capitalize;
  min-width: 100px;
}

.apr-btn:hover {
  border-color: #cfa809;
}
